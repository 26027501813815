.text h1 {
  color: #011718;
  margin-top: -200px;
  font-size: 25em;
  text-align: center;
  text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7),
    -10px 10px 0px rgba(0, 0, 0, 0.4), -15px 15px 0px rgba(0, 0, 0, 0.2);
  font-family: monospace;
  font-weight: bold;
}
.text h2 {
  color: black;
  font-size: 15em;
  text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-top: -150px;
  font-family: monospace;
  font-weight: bold;
}
.text h3 {
  margin-left: 30px;
  color: white;
  font-size: 22em;
  text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
  margin-top: -40px;
  font-family: monospace;
  font-weight: bold;
}
.torch {
  margin: -150px 0 0 -150px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 0 9999em #000000f7;
  opacity: 1;
  border-radius: 50%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
}
